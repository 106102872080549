import type { ParentComponent } from "solid-js";
import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";

const PREVENT_SMOOTH_SCROLL_CLASS = "prevent-scroll";

type StackingContextStore = [
  {
    isTopBarHeaderStacked: boolean;
    isProgramHeaderStacked: boolean;
    isSearchResultsHeaderStacked: boolean;
    hasPreventScrollEnabled: boolean;
    preventScrollLayersCount: number;
  },
  {
    setTopBarHeaderStack?: (setStacked: boolean) => void;
    setProgramHeaderStack?: (setStacked: boolean) => void;
    setSearchResultsHeaderStack?: (setStacked: boolean) => void;
    setPreventScrollEnabled?: (setEnabled: boolean) => void;
    incrementPreventScrollLayerCount?: () => void;
    decreasePreventScrollLayerCount?: () => void;
  },
];

const StackingContext = createContext<StackingContextStore>([
  {
    isTopBarHeaderStacked: false,
    isProgramHeaderStacked: false,
    isSearchResultsHeaderStacked: false,
    hasPreventScrollEnabled: false,
    preventScrollLayersCount: 0,
  },
  {},
]);

export const StackingContextProvider: ParentComponent<{
  isTopBarHeaderStacked: boolean;
  isProgramHeaderStacked: boolean;
  isSearchResultsHeaderStacked: boolean;
  hasPreventScrollEnabled: boolean;
  preventScrollLayersCount: number;
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      isTopBarHeaderStacked: props.isTopBarHeaderStacked || false,
      // eslint-disable-next-line solid/reactivity
      isProgramHeaderStacked: props.isProgramHeaderStacked || false,
      // eslint-disable-next-line solid/reactivity
      isSearchResultsHeaderStacked: props.isSearchResultsHeaderStacked || false,
      // eslint-disable-next-line solid/reactivity
      hasPreventScrollEnabled: props.hasPreventScrollEnabled || false,
      // eslint-disable-next-line solid/reactivity
      preventScrollLayersCount: props.preventScrollLayersCount || 0,
    }),
    store: StackingContextStore = [
      state,
      {
        setTopBarHeaderStack(setStacked: boolean) {
          setState("isTopBarHeaderStacked", setStacked);
        },
        setProgramHeaderStack(setStacked: boolean) {
          setState("isProgramHeaderStacked", setStacked);
        },
        setSearchResultsHeaderStack(setStacked: boolean) {
          setState("isSearchResultsHeaderStacked", setStacked);
        },
        setPreventScrollEnabled(setEnabled: boolean) {
          setState("hasPreventScrollEnabled", setEnabled);
        },
        incrementPreventScrollLayerCount() {
          setState("preventScrollLayersCount", (prev) => prev + 1);
          setState("hasPreventScrollEnabled", true);

          if (state.preventScrollLayersCount === 1) {
            document.documentElement.classList.add(PREVENT_SMOOTH_SCROLL_CLASS);
          }
        },
        decreasePreventScrollLayerCount() {
          setState("preventScrollLayersCount", (prev) => {
            const r = prev - 1;
            return r > -1 ? r : 0;
          });

          setState(
            "hasPreventScrollEnabled",
            state.preventScrollLayersCount > 0,
          );

          if (state.preventScrollLayersCount < 1) {
            document.documentElement.classList.remove(
              PREVENT_SMOOTH_SCROLL_CLASS,
            );
          }
        },
      },
    ];

  return (
    <StackingContext.Provider value={store}>
      {props.children}
    </StackingContext.Provider>
  );
};

export function useStackingContext() {
  return useContext(StackingContext);
}

export function scrollTopIsHigherOrEqualTo(pos: number): boolean {
  return (
    Math.max(document.documentElement.scrollTop, document.body.scrollTop) >= pos
  );
}

export function programBarShouldStack(): boolean {
  // 100vh - 20px of margin + {desktop: 64px, mobile: 54px}
  if (document.documentElement.clientWidth < 768) {
    return scrollTopIsHigherOrEqualTo(450);
  }
  return scrollTopIsHigherOrEqualTo(window.innerHeight - 84);
}

export function searchResultsHeaderBarShouldStack(): boolean {
  // 100vh - 20px of margin + {desktop: 64px, mobile: 54px}
  if (document.documentElement.clientWidth < 768) {
    return scrollTopIsHigherOrEqualTo(136);
  }

  return false;
}

export function topBarShouldStack(): boolean {
  return document.documentElement.scrollTop >= 64;
}
